<template>
	<div id="viewer">
		<div class="checkall">
			<el-checkbox :disabled="onlyView" :indeterminate="isIndeterminate" v-model="checkAll"
				@change="handleCheckAllChange">全选</el-checkbox>
		</div>
		<viewer :images="datas" class="viewer">
			<div v-for="(item, index) in datas" :key="index" class="img-item">
				<div class="img-choose" :style="{visibility:onlyView?'hidden':'visible'}">
					<el-checkbox class="choose" v-model="item.checked" @change="handleCheckedOneChange"></el-checkbox>
				</div>
				<div class="img-show">
					<img :src="item.url">
					<span>{{item.name}}</span>
				</div>
			</div>
		</viewer>
	</div>
</template>

<script>
	import Vue from 'vue';
	import Viewer from 'v-viewer'
	import 'viewerjs/dist/viewer.css'
	Vue.use(Viewer)
	Viewer.setDefaults({
		Options: {
			'inline': true,
			'button': true,
			'navbar': true,
			'title': true,
			'toolbar': true,
			'tooltip': true,
			'movable': true,
			'zoomable': true,
			'rotatable': true,
			'scalable': true,
			'transition': true,
			'fullscreen': true,
			'keyboard': true,
			'url': 'data-source'
		}
	})

	export default {
		data() {
			return {
				onlyView: true, //文件状态：只看/操作
				// 全选
				checkAll: false,
				isIndeterminate: true,

				descImgs: ['https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7',
					'https://tse1-mm.cn.bing.net/th?id=OIP.fLI-fIeiAEMZwLhz6KkcMQAAAA&w=201&h=200&c=7&o=5&pid=1.7'
				],
			}
		},
		props: {
			datas: {
				type: Array
			}
		},
		methods: {
			// 设置文件状态
			setOnlyView(bool) {
				this.onlyView = bool;
			},
			// 全选/反选
			handleCheckAllChange(val) {
				this.datas.forEach((item) => {
					item.checked = val;
				})
				this.isIndeterminate = false;
			},
			handleCheckedOneChange(val) {
				let checkedCount = 0;
				this.datas.forEach((item) => {
					if (item.checked) {
						checkedCount++;
					}
				})
				if (checkedCount == this.datas.length) {
					this.isIndeterminate = false;
				} else {
					this.isIndeterminate = true;
				}
			}
		}
	}
</script>

<style lang="scss">
	$img_w: 200px;
	$img_h: 100px;

	#viewer {

		// 全选栏
		.checkall {
			margin: 15px 0;
		}

		// 图片容器
		.viewer {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax($img_w + 40px, 1fr)); //minmax(最小宽度，最大宽度)
			grid-row-gap: 10px; //行间距
			grid-column-gap: 10px; //列间距

			.img-item {
				position: relative;

				// &:hover .img-choose {
				// 	visibility: visible;
				// }
			}

			.img-choose {
				height: $img_h + 40px;
				border: 1px solid $border_color;
				background-color: rgba($color: $border_color, $alpha: 0.5);
				// visibility: hidden;

				.choose {
					margin: 4px 0 0 6px;
				}
			}

			.img-show {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				margin: 10px auto;

				img {
					width: $img_w;
					height: $img_h;
					border-radius: 4px;

					&:hover {
						@include c-hover;
					}
				}

				span {
					display: inline-block;
					text-align: center;
					width: $img_w + 20px;
					@include c-hide-text;
					margin-top: 5px;
					color: #333333;
				}
			}
		}
	}
</style>
