<template>
	<div id="pictureData" v-loading.fullscreen.lock="loading" element-loading-text="请求中，请稍后"
		element-loading-background="rgba(255, 255, 255, 0.7)">
		<div class="c-query">
			<span>选择设备：</span>
			<el-select v-model="deviceUid" filterable placeholder="请选择" size="mini">
				<el-option v-for="item in devices" :key="item.id" :label="item.title" :value="item.id"></el-option>
			</el-select>
		</div>
		<div class="c-container-box">
			<div class="path">
				<div class="path-name">
					<span v-for="(item,index) of paths" :key="index"
						:class="{pathRoot:index==0,pathStyle:index!=0&&index!=paths.length-1,noClick:index==0||index==paths.length-1}"
						@click="fileClick(1)">
						/ {{item}}
					</span>
				</div>
				<template v-if="user.isAdmin=='1'||user.isManager=='1'">
					<el-button type="primary" size="small" v-show="isFile" @click="mngBtnClick">
						{{delBtnIsShow?'取消管理':'管理'}}
					</el-button>
					<el-button type="primary" plain size="small" v-show="delBtnIsShow" @click="deleteReady('1')">删除
					</el-button>
				</template>
			</div>
			<!-- 文件夹列表 -->
			<el-table :data="fileArr" v-show="!isFile" @selection-change="selectionChange">
				<el-table-column type="selection" width="45"></el-table-column>
				<el-table-column label="文件名">
					<template slot-scope="scope">
						<div class="file-item">
							<div class="left" id="viewImage" @click="fileClick(2,scope.row.name)">
								<i :class="scope.row.type=='folder'?'iconfont icon_folder':'el-icon-picture'"></i>
								<span>{{scope.row.name}}</span>
							</div>
							<div class="right" v-if="user.isAdmin=='1'||user.isManager=='1'">
								<i class="el-icon-delete-solid icon-delete" @click="deleteReady('2',scope.row)"></i>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="size" label="大小"></el-table-column>
			</el-table>
			<!-- 图片文件 -->
			<Viewer ref="viewer" :datas="fileArr" v-show="isFile"></Viewer>
		</div>
		<!-- 删除dialog -->
		<Dialog :data="deleteDialog" @sure="deleteSure">
			<template>
				{{deleteDialog.content}}
			</template>
		</Dialog>
	</div>
</template>

<script>
	import Viewer from '../../components/Viewer.vue';
	import Dialog from '../../components/Dialog.vue';

	const ROOT_PATH = "/media"; //图片根路径

	export default {
		data() {
			return {
				deviceUid: '', //操作的设备
				delBtnIsShow: false, // 删除按钮显示隐藏
				folder: '', //文件夹（路径）
				level: 1, // 路径规则：0(根目录)：/media，1(设备)：/uid，2(日期)：20201201
				folderArr: [], //文件夹——用于删除多个文件夹
				folderOne: {}, //单个文件夹删除
				fileArr: [], //图片文件——用于删除
				isFile: false, //是否为图片文件
				// 删除确认dilog
				deleteDialog: {
					show: false,
					title: '删除',
					width: '400px',
					content: '', //删除提示
					type: '' //1删除多个文件，2删除单个文件
				},
				loading: false,
				user: ''
			}
		},
		components: {
			Viewer,
			Dialog
		},
		computed: {
			devices() {
				let devices = this.$store.getters.getCurDevices;
				if (devices && devices.length > 0) {
					this.deviceUid = devices[0].id;
				}
				return devices;
			},
			paths() {
				let folder = this.folder;
				if (folder) {
					folder = folder.substring(1);
				}
				return folder.split("/");
			}
		},
		watch: {
			deviceUid(val) {
				// 设备变化重新请求
				this.level = 1;
				this.folder = ROOT_PATH + "/" + this.deviceUid;
				this.delBtnIsShow = false;
				this.dataRequest();
			},
			level(val) {
				if (val == 2) {
					this.isFile = true;
				} else {
					this.isFile = false;
				}
			}
		},
		methods: {
			dataRequest() {
				let json = {
					"cmd": "folderContent",
					"folder": this.folder
				}
				this.ws.send(json);
				this.loading = true;
			},

			// 文件夹选择项发生变化
			selectionChange(selection) {
				this.folderArr = selection;
				if (selection.length > 0) {
					this.delBtnIsShow = true;
				} else {
					this.delBtnIsShow = false;
				}
			},
			// 管理按钮点击
			mngBtnClick() {
				this.delBtnIsShow = !this.delBtnIsShow;
				this.$refs.viewer.setOnlyView(!this.delBtnIsShow);
			},

			// 文件删除
			deleteReady(type, data) {
				if (type == '1' && this.isFile) {
					let checkedCount = 0;
					this.fileArr.forEach((item) => {
						if (item.checked) {
							checkedCount++;
						}
					})
					if (checkedCount == 0) {
						this.common.cToast(this, "至少选择一个文件夹");
						return;
					}
				}
				this.deleteDialog.show = true;
				this.deleteDialog.type = type;
				switch (type) {
					case '1':
						this.deleteDialog.content = "您确定删除所选文件吗？";
						break;
					case '2':
						this.deleteDialog.content = "您确定删除文件 “" + data.name + "” 吗？";
						this.folderOne = data;
						break;
				}
			},
			deleteSure() {
				switch (this.deleteDialog.type) {
					case '1':
						if (!this.isFile) {
							// console.log("删除所选文件夹");
							let items = new Array();
							this.folderArr.forEach((item) => {
								items.push(item.name);
							})
							this.deleteFileRequest(items);
						} else {
							// console.log("删除所选文件");
							let items = new Array();
							this.fileArr.forEach((item) => {
								if (item.checked) {
									items.push(item.name);
								}
							})
							this.deleteFileRequest(items);
						}
						break;
					case '2':
						// console.log("删除文件");
						let items = [this.folderOne.name];
						this.deleteFileRequest(items);
						break;
				}
			},
			// 删除文件请求
			deleteFileRequest(items) {
				let json = {
					"cmd": "removeFolderContent",
					"folder": this.folder,
					"items": items,
				}
				this.ws.send(json);
				this.loading = true;
			},

			// 文件点击
			fileClick(level, name) {
				this.level = level;
				switch (level) {
					case 1: //设备
						this.folder = ROOT_PATH + "/" + this.deviceUid;
						break;
					case 2: //日期
						this.folder = this.folder + "/" + name;
						break;
					default:
						break;
				}
				this.delBtnIsShow = false;
				this.$refs.viewer.setOnlyView(!this.delBtnIsShow);
				this.dataRequest();
			}
		},
		mounted() {
			this.user = this.common.cGetUser();

			// 文件列表result
			this.ws.addCallback('folderContentAck', (res) => {
				this.loading = false;
				if (this.common.cGetResult(res)) {
					this.folder = res.folder;
					let arr = new Array();
					let loc = window.location;
					let url = `${loc.protocol}//${loc.host}`;
					//是在node.js环境下运行,连接IotPlatform.exe，这里主要方便测试显示出图片
					if (loc.port == "8080") {
						url = `${loc.protocol}//${document.domain}`;
					}
					for (let item of res.items) {
						// 时间转换
						var reg = /\.(png|jpg|gif|jpeg|webp)$/;
						let img_time=null;
						if(reg.test(item.name)){
							let arr_item = item.name.split(" ")
							if(arr_item[1]){
								let str = arr_item[1].replace(reg,'')
								let  n1	= str.slice(0,2)
								let  n2	= str.slice(2,4)
								let  n3	= str.slice(4,6)
								img_time =arr_item[0]+ ' ' +arr_item[1].replace(str,`${n1}:${n2}:${n3}`)
							}
						}
						arr.push({
							type: item.type,
							name: img_time || item.name,
							size: item.type == 'folder' ? '-' : this.common.cByteConversion(item.size),
							url: item.type == 'folder' ? '' : url + this.folder + "/" + item.name,
							checked: false
						})
					}
					this.fileArr = arr;
				}
			})

			// 删除文件result
			this.ws.addCallback('removeFolderContentAck', (res) => {
				this.loading = false;
				this.deleteDialog.show = false;
				if (this.common.cGetResult(res)) {
					this.common.cResultOk(this);
					this.dataRequest();
				} else {
					this.common.cResultNo(this);
				}
			})
		}
	}
</script>

<style lang="scss">
	@import '../../assets/iconfont/icon_folder.css';

	#pictureData {

		// 文件path
		.path {
			display: flex;
			align-items: center;
			margin-bottom: 5px;
			height: 30px;

			.path-name {
				margin: 0 10px;
			}

			.pathRoot {
				color: #CCCCCC;
			}

			// 不可点击
			.noClick {
				pointer-events: none;
			}

			.pathStyle {
				color: $color_theme;

				&:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}

		// 文件item
		.file-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.left {
				display: flex;
				align-items: center;

				i {
					font-size: 24px;
					margin-right: 10px;
				}

				i.icon_folder {
					color: #EBBC58;
				}

				i.el-icon-picture {
					color: #FC6C4A;
				}

				&:hover {
					cursor: pointer;
				}
			}

			.right {
				i {
					color: $color_red;
					font-size: 18px;
				}
			}

			.icon-delete {
				visibility: hidden;
			}

			&:hover .icon-delete {
				cursor: pointer;
				opacity: 0.9;
				visibility: visible;
			}
		}

		// 重写dialog样式
		.dialog-content {
			padding: 0 10%;
		}

		// 重写element-ui样式
		.el-table th,
		.el-table td {
			text-align: inherit !important;
		}
	}
</style>
